import React from "react";
import styled from "styled-components";
import { H2, P } from "../components/text";
import { device } from "../theme/device";

import photoLine_zumba_desktop2 from "../assets/images/photoLine_zumba_desktop2.svg";

const Wrapper = styled.div`
  padding: 60px 0 110px;
  h6 {
    max-width: 1000px;
  }
  @media ${device.landscape_tablet} {
    padding: 40px 0 0;
  }
  @media ${device.mobile} {
    padding: 20px 0;
    background: none;
  }
`;

const ImageOverlay2 = styled.div`
  height: 100%;
  background: url(${(props) => props.image}) no-repeat;
  background-size: contain;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
  img {
    visibility: hidden;
    width: 100%;
  }
  .text {
    margin: 0 80px 0 100px;
  }

  @media ${device.landscape_tablet} {
    .text {
      margin: 0 35px 0 50px;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    .image {
      margin: 50px;
    }
    .text {
      margin: 0 50px;
    }
  }

  @media ${device.mobile} {
    .image {
      margin: 0 25px;
    }
    .text {
      margin: 25px;
    }
  }
`;

const Camp = ({ data: { acf, title } }) => {
  const { title_1, description, image } = acf;
  return (
    <React.Fragment>
      {title_1 && (
        <Wrapper id="STOVYKLA">
          <H2
            textAlign={{ _: "left" }}
            mr={{ _: "0", sm: "50px", md: "0" }}
            ml={{ _: "25px", sm: "50px", lg: "100px" }}
            mb={{ _: "0", sm: "20px", md: "20px", lg: "30px" }}
          >
            {title_1}
          </H2>
          <Grid>
            <P
              dangerouslySetInnerHTML={{ __html: description }}
              className="text"
            />
            <ImageOverlay2 image={image && image.url} className="image">
              {image && image.url && <img src={image.url} alt={image.alt} />}
            </ImageOverlay2>
          </Grid>
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default Camp;
