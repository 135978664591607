import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const Background = styled.div`
  background-color: ${props =>
    props.bordo ? themeGet("colors.bordo") : themeGet("colors.lightGray")};
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :first-child {
    max-width: 100%;
    /* width: 100%; */
  }
`;
