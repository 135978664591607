import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { H2, H4, P } from "../components/text";
import { device } from "../theme/device";
import { WorkoutItem } from "../components/WorkoutsGrid";

import line_workouts_white from "../assets/images/line_workouts_white.svg";
import line_workouts_button from "../assets/images/line_workouts_button.svg";

const Wrapper = styled.div`
  color: ${themeGet("colors.lightGray")};
  padding: 80px 0 76px;
  width: 100%;
  background: url(${line_workouts_white}) no-repeat right top;
  @media ${device.landscape_tablet} {
    background: none;
  }
`;

const Section = styled.div`
  max-width: 1020px;
  @media ${device.landscape_tablet} {
    max-width: 100%;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 50px 0 20px;
  @media ${device.landscape_tablet} {
    grid-template-columns: 0 1fr 1fr 2fr 2fr auto 0;
  }
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const YellowText = styled.p`
  font-weight: 255;
  font-size: 12px;
  line-height: 16px;
  color: ${themeGet("colors.yellow")};
  @media ${device.landscape_tablet} {
    margin-right: 50px;
  }
  @media ${device.mobile} {
    margin-left: 25px;
    margin-right: 0;
  }
`;

const SignIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 100px;
  div {
    max-width: fit-content;
    text-align: end;
  }
  p {
    max-width: 200px;
  }
  @media ${device.landscape_tablet} {
    align-items: flex-start;
    margin-left: 50px;
  }
  @media ${device.tablet} {
    margin-left: 50px;
    p {
      display: none;
    }
  }
  @media ${device.mobile} {
    margin-left: 25px;
  }
`;

const SignInTitle = styled.button`
  color: ${themeGet("colors.yellow")};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
  width: fit-content;
  &:focus {
    outline: none;
  }
`;

const ButtonWrapper = styled.div`
  background: url(${line_workouts_button}) no-repeat left;
  margin: 0 0 15px;
  background-size: contain;
  padding: 10px 0 10px 15px;
  button {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Workouts = ({ data: { acf, title } }) => {
  const {
    description,
    workouts_1,
    workouts_2,
    workouts_3,
    sign_in_title,
    sign_in_description,
  } = acf;

  const normalizedWorkouts1Data = Object.values(workouts_1);
  const filteredWorkouts1Data = normalizedWorkouts1Data.filter(
    (el) => typeof el === "object" && el !== null
  );

  const normalizedWorkouts2Data = Object.values(workouts_2);
  const filteredWorkouts2Data = normalizedWorkouts2Data.filter(
    (el) => typeof el === "object" && el !== null
  );

  const normalizedWorkouts3Data = Object.values(workouts_3);
  const filteredWorkouts3Data = normalizedWorkouts3Data.filter(
    (el) => typeof el === "object" && el !== null
  );

  const scroll = () => {
    document.getElementById("KONTAKTAI").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Wrapper id="TVARKARAŠTIS">
      <Flex>
        <H2
          color={"#FFD740"}
          ml={{ _: "25px", sm: "50px", lg: "100px" }}
          mb={"14px"}
        >
          {acf.title}
        </H2>
        <SignIn>
          <div>
            <ButtonWrapper>
              <SignInTitle onClick={scroll}>{sign_in_title}</SignInTitle>
            </ButtonWrapper>
            <P
              color={"#F0EFED"}
              fontSize={"12px"}
              lineHeight={"16px"}
              dangerouslySetInnerHTML={{ __html: sign_in_description }}
            />
          </div>
        </SignIn>
      </Flex>
      <Section>
        <H4
          fontWeight={"225"}
          ml={{ _: "25px", sm: "50px", lg: "100px" }}
          mr={{ _: "25px", sm: "50px", lg: "100px" }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <TextWrapper>
          <H4
            ml={{ _: "25px", sm: "50px", lg: "100px" }}
            mr={{ _: "25px", sm: "50px", lg: "150px" }}
          >
            {workouts_1.title}
          </H4>
          <YellowText>{workouts_1.yellow_text}</YellowText>
        </TextWrapper>
        {filteredWorkouts1Data.map(
          (item, index) =>
            item.day && (
              <WorkoutItem key={index}>
                <div className="empty"> </div>
                <P fontWeight={"225"} className="day">
                  {item.day}
                </P>
                <P fontWeight={"225"} className="time">
                  {item.time}
                </P>
                <P fontWeight={"400"} className="name">
                  {item.name}
                </P>
                <P fontWeight={"225"} className="intensity">
                  {item.intensity}
                </P>
                <P fontWeight={"225"} className="price">
                  {item.price}
                </P>
              </WorkoutItem>
            )
        )}
      </Section>
      <Section>
        <TextWrapper>
          <H4
            ml={{ _: "25px", sm: "50px", lg: "100px" }}
            mr={{ _: "25px", sm: "50px", lg: "100px" }}
          >
            {workouts_2.title}
          </H4>
          <YellowText>{workouts_2.yellow_text}</YellowText>
        </TextWrapper>
        {filteredWorkouts2Data.map(
          (item, index) =>
            item.day && (
              <WorkoutItem key={index}>
                <div className="empty"> </div>
                <P fontWeight={"225"} className="day">
                  {item.day}
                </P>
                <P fontWeight={"225"} className="time">
                  {item.time}
                </P>
                <P fontWeight={"400"} className="name">
                  {item.name}
                </P>
                <P fontWeight={"225"} className="intensity">
                  {item.intensity}
                </P>
                <P fontWeight={"225"} className="price">
                  {item.price}
                </P>
              </WorkoutItem>
            )
        )}
      </Section>
      <Section>
        <TextWrapper>
          <H4
            ml={{ _: "25px", sm: "50px", lg: "100px" }}
            mr={{ _: "25px", sm: "50px", lg: "100px" }}
          >
            {workouts_3.title}
          </H4>
          <YellowText>{workouts_3.yellow_text}</YellowText>
        </TextWrapper>
        {filteredWorkouts3Data.map(
          (item, index) =>
            item.day && (
              <WorkoutItem key={index}>
                <div className="empty"> </div>
                <P fontWeight={"225"} className="day">
                  {item.day}
                </P>
                <P fontWeight={"225"} className="time">
                  {item.time}
                </P>
                <P fontWeight={"400"} className="name">
                  {item.name}
                </P>
                <P fontWeight={"225"} className="intensity">
                  {item.intensity}
                </P>
                <P fontWeight={"225"} className="price">
                  {item.price}
                </P>
              </WorkoutItem>
            )
        )}
      </Section>
    </Wrapper>
  );
};

export default Workouts;
