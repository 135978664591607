import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { device } from "../theme/device";

export const WorkoutItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1fr 1fr 2fr 2fr 1fr;
  padding: 10px 0;
  grid-column-gap: 40px;
  border-top: 0.25px solid rgba(240, 239, 237, 0.4);
  > * {
    color: ${themeGet("colors.lightGray")};
    font-size: 14px;
    line-height: 19px;
  }
  &:last-child {
    border-bottom: 0.25px solid rgba(240, 239, 237, 0.4);
  }
  @media ${device.landscape_tablet} {
    grid-template-columns: 0 1fr 1fr 2fr 1fr 1fr 0;
  }
  @media ${device.tablet} {
    grid-template-columns: 3fr 5fr 2fr;
    grid-template-rows: auto auto auto;
    grid-row-gap: 14px;
    grid-column-gap: 20px;
    .empty {
      display: none;
    }
    .day {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-column-end: 4;
      font-weight: 400;
      margin-bottom: 10px;
      margin-left: 50px;
    }
    .time {
      grid-row-start: 2;
      grid-column-start: 1;
      margin-left: 50px;
    }
    .name {
      grid-row-start: 2;
      grid-column-start: 2;
      font-weight: 500;
    }
    .intensity {
      grid-row-start: 3;
      grid-column-start: 2;
    }
    .price {
      grid-row-start: 2;
      grid-column-start: 3;
      margin-right: 50px;
    }
  }
  @media ${device.mobile} {
    grid-template-columns: 2fr 5fr;
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    .day {
      grid-column-end: 3;
      margin-left: 25px;
    }
    .time {
      margin-left: 25px;
    }
    .name,
    .intensity {
      margin-right: 25px;
    }
    .price {
      grid-row-start: 4;
      grid-column-start: 2;
      text-align: end;
      margin-right: 25px;
    }
    > * {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
