import React, { useState, useEffect } from "react";
import Axios from "axios";
import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import { Background } from "./components/globalStyles";
import Loading from "./components/Loading";
import Header from "./sections/Header";
import Zumba from "./sections/Zumba";
import Camp from "./sections/Camp";
import Workouts from "./sections/Workouts";
import CouplesDances from "./sections/CouplesDances";
import Us from "./sections/Us";
import Contacts from "./sections/Contacts";
import Footer from "./sections/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const script = document.createElement("script");

    const scriptText = document.createTextNode(`
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
      .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
      n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
      (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
      ml('account', '827182')
    `);

    script.appendChild(scriptText);
    Axios.get("https://cms.madance.lt/wp-json/wp/v2/madance/").then(
      (response) => {
        setTimeout(() => {
          setData(response.data);
          document.head.appendChild(script);
        }, 3000);
      }
    );
  }, []);

  const filterData = (section) => data.filter((el) => el.slug === section)[0];

  const menuItems = [
    "ŠOKIAI POROSE",
    "ZUMBA",
    "TVARKARAŠTIS",
    "MES",
    "STOVYKLA",
    "KONTAKTAI",
  ];

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <>
            <Background>
              <Header data={filterData("header")} menuItems={menuItems} />
            </Background>
            <Background>
              <CouplesDances data={filterData("sokiai-porose")} />
            </Background>
            <Background>
              <Zumba data={filterData("zumba")} />
            </Background>
            <Background bordo>
              <Workouts data={filterData("treniruotes")} />
            </Background>
            <Background>
              <Us data={filterData("mes")} />
            </Background>
            <Background>
              <Camp data={filterData("stovykla")} />
            </Background>
            <Background>
              <Contacts data={filterData("kontaktai")} />
            </Background>
            <Background bordo>
              <Footer data={filterData("footer")} />
            </Background>
          </>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
