const colors = {
  bordo: "#530644",
  darkGray: "#554D3E",
  lightGray: "#F0EFED",
  yellow: "#FFD740"
};

const breakpoints = ["500px", "770px", "1030px"];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export default {
  breakpoints,
  colors
};
