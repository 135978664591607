import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { H2, H3, H6, P } from "../components/text";
import { Grid1, Grid2, Grid3 } from "../components/UsGrid";
import { device } from "../theme/device";
import { themeGet } from "@styled-system/theme-get";
import line_zumba_right from "../assets/images/line_zumba_right.svg";
import arrow from "../assets/images/arrow.svg";

const Wrapper = styled.div`
  background: url(${line_zumba_right}) no-repeat top -100px right,
    url(${line_zumba_right}) no-repeat bottom 100px right;
  background-size: auto 700px;
  margin-bottom: 100px;
  padding-top: 60px;
  strong {
    font-weight: 500;
  }
  @media ${device.landscape_tablet} {
    background: url(${line_zumba_right}) no-repeat bottom right;
    background-size: auto 600px;
  }
  @media ${device.tablet} {
    background: none;
    margin-bottom: 80px;
  }
  @media ${device.mobile} {
    background: none;
    margin-bottom: 70px;
  }
`;

const H3Wrapper = styled(H3)`
  text-transform: uppercase;
`;

const Carousel = styled(Slider)`
  margin-top: 100px;
  .slick-dots {
    display: flex !important;
    width: calc(50% - 20px);
    li {
      width: 100%;
      &:first-child {
        margin-left: -4px;
      }
      button:before {
        content: "";
        width: 100%;
        height: 0;
        border: 1px solid ${themeGet("colors.yellow")};
        border-radius: 5px;
        background-color: ${themeGet("colors.yellow")};
        opacity: 1;
        margin-top: 13px;
      }
    }
    li:before {
      display: none;
    }
    .slick-active {
      button:before {
        border: 1px solid ${themeGet("colors.bordo")};
        background-color: ${themeGet("colors.bordo")};
      }
    }
    @media ${device.landscape_tablet} {
      width: calc(66% - 20px);
    }
    @media ${device.tablet} {
      width: 100%;
      bottom: 100px;
    }
    @media ${device.mobile} {
      bottom: 33%;
    }
  }
  .slick-next,
  .slick-prev {
    z-index: 10;
  }
  .slick-prev {
    left: 15px;
    background: url(${arrow});
    height: 30px;
    width: 15px;
    top: calc(50% + 14px);
    :before {
      content: "";
    }
    @media ${device.tablet} {
      top: calc(40% + 14px);
    }
    @media ${device.mobile} {
      top: calc(27% + 14px);
    }
  }
  .slick-next {
    right: calc(50% + 35px);
    background: url(${arrow});
    transform: rotate(180deg);
    height: 30px;
    width: 15px;
    grid-column-start: 1;
    grid-column-end: 2;
    :before {
      content: "";
    }
    @media ${device.landscape_tablet} {
      right: calc(33% + 45px);
    }
    @media ${device.tablet} {
      right: 15px;
      top: 40%;
    }
    @media ${device.mobile} {
      right: 15px;
      top: 27%;
    }
  }
`;

const Us = ({ data: { acf, title } }) => {
  const {
    trainer_1,
    trainer_2,
    trainer_3,
    trainer_4,
    yellow_text_trainer_2,
    yellow_text_trainer_4,
    gallery_photos,
  } = acf;
  const slides = Object.values(gallery_photos).filter((item) => item.image);

  return (
    <Wrapper id="MES">
      <H2
        mb={{ _: "10px", sm: "40px", lg: "75px" }}
        ml={{ _: "25px", sm: "50px", lg: "100px" }}
      >
        {acf.title}
      </H2>
      {trainer_1.name && (
        <Grid1>
          {trainer_1.image && trainer_1.image.url && (
            <img
              src={trainer_1.image.url}
              alt={trainer_1.image.alt}
              className="image"
            />
          )}
          <div className="text">
            <H3 mb={"10px"}>{trainer_1.name}</H3>
            <P dangerouslySetInnerHTML={{ __html: trainer_1.description }} />
          </div>
          <P
            dangerouslySetInnerHTML={{ __html: trainer_1.description2 }}
            className="mazena-text"
          />
        </Grid1>
      )}
      {trainer_2.name && (
        <Grid2>
          <div className="text">
            <H3 mb={"10px"}>{trainer_2.name}</H3>
            <P dangerouslySetInnerHTML={{ __html: trainer_2.description }} />
          </div>
          {trainer_2.image && trainer_2.image.url && (
            <img
              src={trainer_2.image.url}
              alt={trainer_2.image.alt}
              class="image"
            />
          )}
          <H6 className="smallerFont yellow">{yellow_text_trainer_2}</H6>
        </Grid2>
      )}
      {trainer_3.name && (
        <Grid1>
          {trainer_3.image && trainer_3.image.url && (
            <img
              src={trainer_3.image.url}
              alt={trainer_3.image.alt}
              className="image"
            />
          )}
          <div className="text">
            <H3 mb={"10px"}>{trainer_3.name}</H3>
            <P dangerouslySetInnerHTML={{ __html: trainer_3.description }} />
          </div>
          <P
            dangerouslySetInnerHTML={{ __html: trainer_3.description2 }}
            className="mazena-text"
          />
        </Grid1>
      )}
      {trainer_4.name && (
        <Grid2>
          <div className="text">
            <H3 mb={"10px"}>{trainer_4.name}</H3>
            <P dangerouslySetInnerHTML={{ __html: trainer_4.description }} />
          </div>
          {trainer_4.image && trainer_4.image.url && (
            <img
              src={trainer_4.image.url}
              alt={trainer_4.image.alt}
              class="image"
            />
          )}
          <H6 className="smallerFont yellow">{yellow_text_trainer_4}</H6>
        </Grid2>
      )}
      <Carousel fade={true} dots={true} autoplay={true} autoplaySpeed={3000}>
        {slides.map((item) => (
          <Grid3 key={item.image.id}>
            <img src={item.image.url} alt={item.image.alt} />
            <div>
              <H3Wrapper mb={"10px"}>{item.title}</H3Wrapper>
              <P dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          </Grid3>
        ))}
      </Carousel>
    </Wrapper>
  );
};

export default Us;
