import React from "react";
import styled from "styled-components";
import { H1, P } from "../components/text";
import Navigation from "../components/Navigation";
import { device } from "../theme/device";

import ellipse_desktop from "../assets/images/ellipse_desktop.svg";
import lines_header_desktop from "../assets/images/lines_header_desktop.svg";
import lines_header_mobile from "../assets/images/lines_header_mobile.svg";

const Wrapper = styled.div`
  width: 100%;
  background: url(${lines_header_desktop}) no-repeat top right 100px;
  background-size: 100% 100%;
  height: 100vh;

  @media ${device.landscape_tablet} {
    background: url(${lines_header_desktop}) no-repeat top 0 right 25px;
    background-size: 100% 100%;
    height: 580px;
  }
  @media ${device.tablet} {
    background-size: auto 100%;
    height: 70vh;
  }

  @media ${device.mobile} {
    background: url(${lines_header_mobile}) no-repeat;
    background-size: 100% 110%;
    height: 800px;
  }
`;

const TextWrapper = styled.div`
  margin-top: 170px;
  margin-left: 240px;
  padding: 10px 0 10px 25px;
  background: url(${ellipse_desktop}) no-repeat left;
  background-size: contain;
  @media ${device.landscape_tablet} {
    margin-left: 180px;
    padding: 5px 0 5px 25px;
    margin-top: 100px;
  }
  @media ${device.tablet} {
    margin-left: 140px;
  }
  @media ${device.mobile} {
    margin-top: 120px;
    margin-left: 0;
    padding: 50px 0 50px 25px;
  }
`;

const Header = ({ data: { acf }, menuItems }) => {
  const { title, description } = acf;
  return (
    <Wrapper>
      <Navigation menuItems={menuItems} />
      <TextWrapper>
        <H1>{title}</H1>
        <P dangerouslySetInnerHTML={{ __html: description }} />
      </TextWrapper>
    </Wrapper>
  );
};

export default Header;
