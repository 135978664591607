import React from "react";
import styled from "styled-components";
import { H2, H6, P } from "../components/text";
import { device } from "../theme/device";
import { Grid1, Grid2 } from "../components/ZumbaGrids.js";

import line_zumba_right from "../assets/images/line_zumba_right.svg";
import line_zumba_left from "../assets/images/line_zumba_left.svg";
import photoLine_zumba_desktop1 from "../assets/images/photoLine_zumba_desktop1.svg";
import photoLine_zumba_desktop2 from "../assets/images/photoLine_zumba_desktop2.svg";

const Wrapper = styled.div`
  padding: 60px 0 110px;
  background: url(${line_zumba_right}) no-repeat right;
  h6 {
    max-width: 1000px;
  }
  @media ${device.tablet} {
    background: url(${line_zumba_left}) no-repeat left bottom 200px;
  }
  @media ${device.mobile} {
    padding: 20px 0;
    background: none;
  }
`;

const ImageOverlay1 = styled.div`
  height: 100%;
  background: url(${photoLine_zumba_desktop1}),
    url(${(props) => props.image}) no-repeat;
  background-size: contain;
`;

const ImageOverlay2 = styled.div`
  height: 100%;
  background: url(${photoLine_zumba_desktop2}),
    url(${(props) => props.image}) no-repeat;
  background-size: contain;
`;

const Zumba = ({ data: { acf, title } }) => {
  const {
    title_1,
    title_2,
    description_1,
    description_2,
    list_1,
    list_2,
    image_1,
    image_2,
    yellow_text,
  } = acf;
  return (
    <Wrapper id="ZUMBA">
      <H2
        textAlign={{ _: "left", sm: "left", md: "right" }}
        mr={{ _: "0", md: "50px", lg: "100px" }}
        ml={{ _: "25px", sm: "50px", md: "0" }}
        mb={{ _: "0", sm: "20px", md: "20px", lg: "30px" }}
      >
        {title_1}
      </H2>
      <Grid1>
        <ImageOverlay1 image={image_1 && image_1.url} className="image">
          {image_1 && image_1.url && (
            <img src={image_1.url} alt={image_1.alt} />
          )}
        </ImageOverlay1>
        <P
          className="text"
          dangerouslySetInnerHTML={{ __html: description_1 }}
        />
        <P className="list" dangerouslySetInnerHTML={{ __html: list_1 }} />
      </Grid1>
      <H6
        my={{ _: "50px", sm: "90px", lg: "50px" }}
        ml={{ _: "25px", sm: "50px", lg: "100px" }}
      >
        {yellow_text}
      </H6>
      <H2
        textAlign={{ _: "left", sm: "right", md: "left" }}
        mr={{ _: "0", sm: "50px", md: "0" }}
        ml={{ _: "25px", sm: "0", md: "50px", lg: "100px" }}
        mb={{ _: "0", sm: "20px", md: "20px", lg: "30px" }}
      >
        {title_2}
      </H2>
      <Grid2>
        <P
          dangerouslySetInnerHTML={{ __html: description_2 }}
          className="text"
        />
        <ImageOverlay2 image={image_2 && image_2.url} className="image">
          {image_2 && image_2.url && (
            <img src={image_2.url} alt={image_2.alt} />
          )}
        </ImageOverlay2>
        <P className="list" dangerouslySetInnerHTML={{ __html: list_2 }} />
      </Grid2>
    </Wrapper>
  );
};

export default Zumba;
