import styled from "styled-components";
import {
  space,
  fontSize,
  fontWeight,
  color,
  textAlign,
  lineHeight
} from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { device } from "../theme/device";

export const H1 = styled.h1`
  color: ${themeGet("colors.bordo")};
  font-weight: bold;
  font-size: 54px;
  line-height: 74px;

  @media ${device.landscape_tablet} {
    font-size: 46px;
    line-height: 63px;
  }
  @media ${device.tablet} {
    font-size: 44px;
    line-height: 60px;
  }
  @media ${device.mobile} {
    font-size: 28px;
    line-height: 38px;
  }
`;

export const H2 = styled.h2`
  color: ${themeGet("colors.bordo")};
  font-weight: 500;
  font-size: 48px;
  line-height: 65px;
  ${space}
  ${textAlign}
  ${color}

  @media ${device.landscape_tablet} {
    font-size: 42px;
    line-height: 57px;
  }
  @media ${device.tablet} {
    font-size: 40px;
    line-height: 55px;
  }
  @media ${device.mobile} {
    font-size: 36px;
    line-height: 49px;
  }
`;

export const H3 = styled.h3`
  color: ${themeGet("colors.bordo")};
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  ${space}
  @media ${device.landscape_tablet} {
    font-size: 20px;
    line-height: 27px;
  }
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 27px;
  }
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 27px;
  }
`;

export const H4 = styled.h4`
  color: ${themeGet("colors.lightGray")};
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  ${space}
  ${fontWeight}
  @media ${device.landscape_tablet} {
    font-size: 18px;
    line-height: 25px;
  }
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 25px;
  }
  @media ${device.mobile} {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const H5 = styled.h5`
  color: ${themeGet("colors.bordo")};
  font-size: 33px;
  line-height: 45px;
  font-weight: 400;
  ${space}
`;

export const H6 = styled.h6`
  color: ${themeGet("colors.yellow")};
  font-weight: 255;
  font-size: 100px;
  line-height: 136px;
  text-transform: uppercase;
  ${space}
  ${fontSize}
  ${lineHeight}

  @media ${device.landscape_tablet} {
    font-size: 72px;
    line-height: 98px;
  }
  @media ${device.tablet} {
    font-size: 64px;
    line-height: 87px;
  }
  @media ${device.mobile} {
    font-size: 36px;
    line-height: 49px;
  }
`;

export const P = styled.p`
  color: ${themeGet("colors.darkGray")};
  font-weight: 255;
  font-size: 16px;
  line-height: 22px;
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${color}
  @media ${device.landscape_tablet} {
    font-size: 14px;
    line-height: 19px;
  }
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 19px;
  }
  @media ${device.mobile} {
    font-size: 14px;
    line-height: 19px;
  }
`;
