import React, { useState } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { device } from "../theme/device";
import { OpenMenuIcon } from "../assets/icons";
import NavModal from "./NavModal";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  align-items: center;
  padding-top: 32px;
  padding-left: 100px;
  @media ${device.landscape_tablet} {
    padding-left: 50px;
  }
  @media ${device.tablet} {
    justify-content: space-between;
    padding-right: 50px;
    padding-top: 25px;
  }
  @media ${device.mobile} {
    padding-right: 25px;
    padding-left: 5px;
  }
`;
const DesktopMenu = styled.ul`
  display: flex;
  color: ${themeGet("colors.bordo")};
  width: 100%;
  justify-content: center;
  @media ${device.tablet} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  @media ${device.landscape_tablet} {
    transform: scale(0.75);
  }
  @media ${device.mobile} {
    transform: scale(0.6);
  }
`;

const MobileMenu = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const ButtonWrapper = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  position: fixed;
  z-index: 5;
  right: 44px;
  top: 25px;
  @media ${device.mobile} {
    right: 15px;
    top: 25px;
  }
`;

const MenuItem = styled.li`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  &:before {
    display: none;
  }
  &:not(:last-child) {
    margin-right: 36px;
  }
`;

const Navigation = ({ menuItems }) => {
  const [modal, setModal] = useState(false);
  const handleModal = () => setModal(!modal);
  const scroll = id => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth"
    });
  };
  return (
    <>
      {modal && <NavModal menuItems={menuItems} handleModal={handleModal} />}
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <DesktopMenu>
          {menuItems.lenght !== 0 &&
            menuItems.map(item => (
              <MenuItem key={item} onClick={() => scroll(item)}>{item}</MenuItem>
            ))}
        </DesktopMenu>
        <MobileMenu>
          <ButtonWrapper onClick={handleModal}>
            <OpenMenuIcon />
          </ButtonWrapper>
        </MobileMenu>
      </Wrapper>
    </>
  );
};

export default Navigation;
