import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { P } from "../components/text";
import { ReactComponent as LogoYellow } from "../assets/images/logo_yellow.svg";
import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  ZumbaIcon
} from "../assets/icons";
import { device } from "../theme/device";

const WidthWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  color: ${themeGet("colors.lightGray")};
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 6fr 2fr;
  padding: 50px 100px 20px;
  @media ${device.landscape_tablet} {
    padding: 35px 50px 20px;
    grid-template-columns: 2fr 6fr 2fr;
    a,
    svg {
      transform: scale(0.8);
    }
  }
  @media ${device.tablet} {
    align-items: start;
    grid-template-columns: 1fr 2fr 2fr;
    a {
      transform: scale(1.2);
    }
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    padding: 35px 25px 20px;
    .info {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 2;
    }
  }
`;

const Socials = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  a {
    width: fit-content;
  }
  @media ${device.mobile} {
    grid-template-columns: auto auto;
    grid-row-gap: 20px;
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  a {
    text-decoration: none;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    margin-left: 20%;
  }
  @media ${device.mobile} {
    margin-left: 0;
  }
`;

const Copywrite = styled.div`
  color: ${themeGet("colors.lightGray")};
  font-weight: 255;
  margin-bottom: 5px;
  margin-left: 100px;
  font-size: 8px;
  line-height: 11px;
  @media ${device.landscape_tablet} {
    margin-left: 60px;
  }
  @media ${device.mobile} {
    margin-left: 25px;
  }
`;

const Footer = ({ data: { acf } }) => {
  const { text_under_logo, info1, info2, info3, socials } = acf;
  const email = "mailto:" + info1.text;
  const phone = "tel:" + info2.text;
  const address =
    "https://www.google.com/maps/place/Balsi%C5%B3+pagrindin%C4%97+mokykla,+Bubilo+g.+8,+Vilnius+08400/@54.7797168,25.3579922,17z/data=!3m1!4b1!4m5!3m4!1s0x46dd99fe7ee01743:0x3de8b0e40ac5c11c!8m2!3d54.7797168!4d25.3601809";
  return (
    <WidthWrapper>
      <Wrapper>
        <LogoYellow />
        <InfoWrapper className="info">
          <div>
            <P
              color={"#F0EFED"}
              fontWeight={"255"}
              fontSize={"11px"}
              lineHeight={"15px"}
            >
              {info1.title}
            </P>
            <a href={email}>
              <P
                color={"#F0EFED"}
                fontWeight={"400"}
                fontSize={"11px"}
                lineHeight={"15px"}
                mt={{ md: "10px", lg: "14px" }}
              >
                {info1.text}
              </P>
            </a>
          </div>
          <div>
            <P
              color={"#F0EFED"}
              fontWeight={"255"}
              fontSize={"11px"}
              lineHeight={"15px"}
            >
              {info2.title}
            </P>
            <a href={phone}>
              <P
                color={"#F0EFED"}
                fontWeight={"400"}
                fontSize={"11px"}
                lineHeight={"15px"}
                mt={{ md: "10px", lg: "14px" }}
              >
                {info2.text}
              </P>
            </a>
          </div>
          <div>
            <P
              color={"#F0EFED"}
              fontWeight={"255"}
              fontSize={"11px"}
              lineHeight={"15px"}
            >
              {info3.title}
            </P>
            <a href={address} target="_blanc">
              <P
                color={"#F0EFED"}
                fontWeight={"400"}
                fontSize={"11px"}
                lineHeight={"15px"}
                mt={{ md: "10px", lg: "14px" }}
              >
                {info3.text}
              </P>
            </a>
          </div>
        </InfoWrapper>
        <Socials>
          {socials.facebook_link && (
            <a
              href={socials.facebook_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
          )}
          {socials.instagram_link && (
            <a
              href={socials.instagram_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
          )}
          {socials.youtube_link && (
            <a
              href={socials.youtube_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeIcon />
            </a>
          )}
          {socials.zumba_link && (
            <a
              href={socials.zumba_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ZumbaIcon />
            </a>
          )}
        </Socials>
      </Wrapper>
      <Copywrite>{text_under_logo}</Copywrite>
    </WidthWrapper>
  );
};

export default Footer;
