import styled from "styled-components";
import { device } from "../theme/device";
import { themeGet } from "@styled-system/theme-get";

export const Grid1 = styled.div`
  display: grid;
  grid-template-columns: 11fr 15fr;
  grid-column-gap: 80px;
  padding-left: 100px;
  padding-right: 200px;
  img {
    width: 100%;
  }
  .image {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .mazena-text {
    grid-column-start: 2;
  }
  @media ${device.landscape_tablet} {
    grid-column-gap: 35px;
    padding: 0 50px;
    .image {
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }
  @media ${device.tablet} {
    grid-column-gap: 25px;
    grid-template-columns: 3fr 2fr;
    padding-right: 0;
    .image {
      grid-column-start: 2;
    }
    .text {
      grid-column-start: 1;
      grid-row-start: 1;
    }
    .mazena-text {
      color: ${themeGet("colors.bordo")};
      grid-column-start: 1;
      grid-row-start: 2;
      grid-column-end: 3;
      padding-right: 150px;
    }
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
    padding-left: 25px;
    grid-gap: 0;
    .image {
      grid-column-start: 1;
      grid-row-start: 2;
      grid-row-end: 3;
      margin-top: 25px;
    }
    .text {
      padding-right: 25px;
    }
    .mazena-text {
      color: ${themeGet("colors.bordo")};
      grid-row-start: 4;
      padding-right: 25px;
    }
  }
`;

export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-template-rows: auto auto;
  grid-column-gap: 80px;
  margin-top: 75px;
  padding: 0 100px;
  margin-bottom: 120px;
  img {
    width: 100%;
  }
  .image {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .yellow {
    margin-top: 40px;
  }
  @media ${device.landscape_tablet} {
    grid-column-gap: 35px;
    padding: 0 50px;
  }
  @media ${device.tablet} {
    grid-column-gap: 25px;
    grid-template-columns: 1fr 1fr;
    padding: 0 50px 0 0;
    margin-bottom: 80px;
    .yellow {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 3;
      margin: 0 0 40px 50px;
    }
    .image {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .text {
      grid-row-start: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
    padding: 0 25px 0 0;
    margin-top: 40px;
    margin-bottom: 70px;
    .yellow {
      grid-column-end: 2;
      margin-left: 25px;
    }
    .image {
      grid-row-start: 3;
    }
    .text {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
      margin-left: 25px;
      margin-bottom: 25px;
    }
  }
  .smallerFont {
    font-size: 64px;
    line-height: 87px;
    @media ${device.landscape_tablet} {
      font-size: 48px;
      line-height: 65px;
    }
    @media ${device.tablet} {
      font-size: 54px;
      line-height: 74px;
    }
    @media ${device.mobile} {
      font-size: 30px;
      line-height: 41px;
    }
  }
`;
export const Grid3 = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  align-items: end;
  img {
    width: 100%;
  }
  @media ${device.landscape_tablet} {
    grid-template-columns: 2fr 1fr;
    padding-right: 50px;
    margin-bottom: 0;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
    padding-right: 100px;
    & > :nth-child(2) {
      padding-left: 50px;
    }
  }
  @media ${device.mobile} {
    padding-right: 0;
    & > :nth-child(2) {
      padding: 0 25px;
    }
  }

  .slick-slider {
    display: grid;
  }
`;
