import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { H2, P } from "../components/text";
import Form from "../components/Form";
import { device } from "../theme/device";
import line_zumba_right from "../assets/images/line_zumba_right.svg";

const WidthWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 50px 150px 100px 100px;
  background: url(${line_zumba_right}) no-repeat center right;
  background-size: auto 700px;
  @media ${device.landscape_tablet} {
    padding: 120px 130px 100px 50px;
    background: none;
  }
  @media ${device.tablet} {
    padding: 100px 0;
    background: url(${line_zumba_right}) no-repeat top right;
    background-size: auto 900px;
  }
  @media ${device.mobile} {
    padding: 80px 0 50px 0;
    background: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr;
  grid-column-gap: 150px;
  h2 {
    font-weight: 400;
  }
  > * {
    color: ${themeGet("colors.bordo")};
  }
  @media ${device.landscape_tablet} {
    grid-template-columns: 3fr 4fr;
    grid-column-gap: 100px;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
    & > :nth-child(2) {
      margin-top: 50px;
      max-width: 100%;
    }
  }
  @media ${device.mobile} {
    & > :nth-child(2) {
      margin-top: 50px;
      max-width: 100%;
    }
  }
`;

const FormWrapper = styled.div`
  @media ${device.tablet} {
    padding: 0 30px;
    .embedForm {
      max-width: 100% !important;
    }
  }
  @media ${device.mobile} {
    padding: 0 5px;
  }
`;

const Contacts = ({ data: { acf, title } }) => {
  const { description, contacts_form } = acf;
  return (
    <WidthWrapper>
      <Wrapper id="KONTAKTAI">
        <H2 mb={{ _: "50px", lg: "75px" }} mx={{ _: "25px", sm: "50px" }}>
          {acf.title}
        </H2>
        <Grid>
          <P
            dangerouslySetInnerHTML={{ __html: description }}
            mx={{ _: "25px", sm: "50px" }}
          />
          <FormWrapper>
            <Form form={contacts_form} />
          </FormWrapper>
        </Grid>
      </Wrapper>
    </WidthWrapper>
  );
};

export default Contacts;
