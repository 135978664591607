const size = {
  desktop: "1030px",
  tablet: "770px",
  mobile: "500px"
};

export const device = {
  landscape_tablet: `(max-width: ${size.desktop})`,
  tablet: `(max-width: ${size.tablet})`,
  mobile: `(max-width: ${size.mobile})`
};
