import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { P } from "./text";

import line_contacts_button from "../assets/images/line_contacts_button.svg";

const Wrapper = styled.div`
  .ml-form-embedWrapper {
    background: none !important;
  }
  label {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: ${themeGet("colors.bordo")} !important;
    margin-bottom: 12px !important;
  }

  input,
  textarea {
    height: 40px !important;
    background-color: transparent !important;
    border: 0.5px solid #530644 !important;
    margin-bottom: 40px !important;
    font-size: 16px !important;
    color: ${themeGet("colors.darkGray")} !important;
  }
  textarea {
    min-height: 180px !important;
  }
  ul {
    list-style-type: none !important;
    li {
      display: flex !important;
      align-items: center !important;
      &:before {
        display: none !important;
      }
    }
  }
  input[type="checkbox"] {
    margin-bottom: 0 !important;
    margin-right: 20px !important;
  }

  .custom-checkbox-radio-list * {
    font-weight: bold !important;
  }

  .ml-form-embedSubmit {
    padding: 12px 16px !important;
    cursor: pointer !important;
    border: 0 !important;
    margin-top: 32px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    background-color: transparent !important;
    color: ${themeGet("colors.bordo")} !important;
    background: url(${line_contacts_button}) no-repeat left !important;
    background-size: contain !important;
    width: fit-content !important;
    text-align: left;
  }
`;

const Form = ({ form }) => {
  return (
    <Wrapper>
      <P dangerouslySetInnerHTML={{ __html: form }} />
    </Wrapper>
  );
};

export default Form;
