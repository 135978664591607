import styled from 'styled-components';
import { device } from "../theme/device";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 50px;
  img{
    width: 100%;
  }
  .dances{
    grid-column-start: 1;
    grid-column-end: 4;
    margin-right: 100px;
  }
  .text{
    grid-column-start: 4;
    grid-column-end: 6;
    margin-right: 100px;
  }
  .yellow{
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 6;
  }
  .list{
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    align-items: center;
  }
  .image{
    grid-row-start: 3;
    grid-column-start: 3;
    grid-column-end: 6;
  }
  @media ${device.landscape_tablet} {
    grid-template-columns: repeat(2, 1fr);
    h6{
      max-width: 800px;
    }
    .dances{
      grid-column-end: 2;
      margin: 0 50px;
    }
    .text{
      grid-column-start: 2;
      grid-column-end: 3;
    }
    .yellow{
      grid-column-end: 3;
      margin-left: 50px;
    }
    .list{
      grid-column-end: 2;
      margin-left: 50px;
    }
    .image{
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(5, 1fr);
    h6{
      max-width: 600px;
    }
    .dances{
      grid-column-end: 6;
    }
    .text{
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 3;
      grid-column-end: 6;
      margin-right: 50px;
    }
    .yellow{
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 4;
    }
    .list{
      grid-row-start: 5;
      grid-row-end: 6;
      grid-column-start: 3;
      grid-column-end: 6;
      margin-right: 50px;
      margin-left: 0;
    }
    .image{
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    .dances{
      grid-column-end: 2;
      margin-right: 0;
      margin-left: 25px;
    }
    .text{
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 1;
      grid-column-end: 2;
      margin-right: 0;
      margin-left: 25px;
    }
    .yellow{
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
      margin-left: 0;
      margin-left: 25px;
    }
    .list{
      grid-row-start: 5;
      grid-row-end: 6;
      grid-column-start: 1;
      grid-column-end: 2;
      margin-right: 0;
      margin-left: 25px;
    }
    .image{
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
`
