import styled from 'styled-components';
import { device } from "../theme/device";

export const Grid1 = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
  img{
    visibility: hidden;
    width: 100%;
  }

  .image {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .text {
    margin: 0 100px 0 88px;
  }
  .list {
    grid-column-start: 2;
    grid-row-start: 2;
    margin: 0 100px 0 88px;
  }

  @media ${device.landscape_tablet} {
    .image {
      grid-row-end: 2;
    }
    .text {
      margin: 0 50px 0 35px;
    }
    .list {
      grid-column-start: 1;
      grid-column-end: 3;
      margin: 50px 130px 0 50px;
    }
    ul {
      display: grid;
      grid-column-gap: 130px;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    .image {
      grid-row-start: 2;
      grid-column-start: 1;
      margin: 50px 200px 0 0;
    }
    .text {
      grid-row-start: 1;
      grid-column-start: 1;
      margin: 0 200px 0 50px;
    }
    .list {
      grid-row-start: 3;
      grid-column-start: 1;
      margin: 50px 50px 0 50px;
    }
    ul {
      grid-column-gap: 50px;
    }
  }

  @media ${device.mobile} {
    .image {
      margin: 0 0 0 25px;
    }
    .text {
      margin: 25px;
    }
    .list {
      margin: 25px;
    }
    ul {
      grid-template-columns: 1fr;
    }
  }

`

export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
  img{
    visibility: hidden;
    width: 100%;
  }
  .image {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .text {
    margin: 0 80px 0 100px;
  }
  .list {
    grid-column-start: 1;
    grid-row-start: 2;
    margin: 0 80px 0 100px;
  }

  @media ${device.landscape_tablet} {
    .image {
      grid-row-end: 2;
      grid-column-start: 2;
    }
    .text {
      margin: 0 35px 0 50px;
    }
    .list {
      grid-column-start: 1;
      grid-column-end: 3;
      margin: 50px 130px 0 50px;
    }
    ul {
      display: grid;
      grid-column-gap: 130px;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    .image {
      grid-row-start: 2;
      grid-column-start: 1;
      margin: 50px 0 0 200px;
    }
    .text {
      grid-row-start: 1;
      grid-column-start: 1;
      margin: 0 50px 0 200px;
    }
    .list {
      grid-row-start: 3;
      grid-column-start: 1;
      margin: 50px 50px 0 50px;
    }
    ul {
      grid-column-gap: 50px;
    }
  }

  @media ${device.mobile} {
    .image {
      margin: 0 0 0 25px;
    }
    .text {
      margin: 25px;
    }
    .list {
      margin: 25px;
    }
    ul {
      grid-template-columns: 1fr;
    }
  }
`

