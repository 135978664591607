import React from "react";
import styled from "styled-components";
import { H2, H3, H6, P } from "../components/text";
import { device } from "../theme/device";
import { Grid } from "../components/CouplesDancesGrid";

import line_zumba_right from "../assets/images/line_zumba_right.svg";
import line_zumba_left from "../assets/images/line_zumba_left.svg";

const Wrapper = styled.div`
  padding: 90px 0 0 100px;
  strong {
    font-weight: 500;
  }
  @media ${device.landscape_tablet} {
    padding: 90px 0 40px 0;
    background: url(${line_zumba_right}) no-repeat right top;
    background-size: auto 600px;
  }
  @media ${device.tablet} {
    background: url(${line_zumba_right}) no-repeat right,
      url(${line_zumba_left}) no-repeat left bottom;
    background-size: auto 600px;
  }
  @media ${device.mobile} {
    padding: 90px 25px 40px 0;
    background: none;
  }
`;
const DancesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  @media ${device.landscape_tablet} {
    grid-template-columns: 1fr;
  }
  @media ${device.landscape_tablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const CouplesDances = ({ data: { acf, title } }) => {
  const {
    dance_1,
    dance_2,
    dance_3,
    dance_4,
    dances_description,
    yellow_text,
    description,
    image,
  } = acf;
  const dances = [dance_1, dance_2, dance_3, dance_4];
  return (
    <Wrapper id="ŠOKIAI POROSE">
      <H2
        mb={{ _: "40px", lg: "75px" }}
        ml={{ _: "25px", sm: "50px", lg: "0" }}
      >
        {acf.title}
      </H2>
      <Grid>
        <DancesGrid className="dances">
          {dances.map((item) => (
            <div key={item.title}>
              <H3>{item.title}</H3>
              <P>{item.description}</P>
            </div>
          ))}
        </DancesGrid>
        <P
          dangerouslySetInnerHTML={{ __html: dances_description }}
          className="text"
        />
        <H6 className="yellow">{yellow_text}</H6>
        <P dangerouslySetInnerHTML={{ __html: description }} className="list" />
        {image && image.url && (
          <img src={image.url} alt={image.alt} className="image" />
        )}
      </Grid>
    </Wrapper>
  );
};

export default CouplesDances;
