import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { device } from "../theme/device";

import LoadingGif from "../assets/images/loading3.gif";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background: url(${LoadingGif}) no-repeat center #510441;
  background-size: 30%;
  z-index: 100;

  @media ${device.mobile} {
    background-size: 50%;
  }
`;

const Loading = ({ form }) => {
  return <Wrapper />;
};

export default Loading;
