import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { device } from "../theme/device";
import { CloseMenuIcon } from "../assets/icons";
import yellow_line from "../assets/images/yellow_line.svg";

const Modal = styled.div`
  height: 100vh;
  position: fixed;
  background-color: ${themeGet("colors.bordo")};
  width: 100%;
  overflow: hidden;
  z-index: 10;
  color: ${themeGet("colors.lightGray")};
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  background: url(${yellow_line}) no-repeat right 200px,
    ${themeGet("colors.bordo")};
  background-size: auto 350px;
`;

const ModalWrapper = styled.div`
  padding: 40px 0 0 50px;
`;

const ButtonWrapper = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  padding-bottom: 80px;
  @media ${device.mobile} {
    padding-right: 20px;
  }
`;

const MenuItem = styled.div`
  margin-bottom: 30px;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;
`;

const NavModal = ({ menuItems, handleModal, handleScroll }) => {
  const scroll = id => {
    handleModal();
    document.getElementById(id).scrollIntoView({
      behavior: "smooth"
    });
  };
  return (
    <Modal>
      <ModalWrapper>
        <CloseButton>
          <ButtonWrapper onClick={handleModal}>
            <CloseMenuIcon />
          </ButtonWrapper>
        </CloseButton>
        {menuItems.lenght !== 0 &&
          menuItems.map(item => (
            <MenuItem onClick={() => scroll(item)}>{item}</MenuItem>
          ))}
      </ModalWrapper>
    </Modal>
  );
};

export default NavModal;
